import React, { Component } from 'react';

import ProductList from 'Component/ProductList';

/** @namespace Pwa/Component/ProductListWidgetExtended/Component/ProductListWidgetExtended */
export class ProductListWidgetExtended extends Component {
    render() {
        const { totalItems } = this.props;

        return (
            <ProductList
                {...this.props}
                {...this.containerFunctions}
                isInfiniteLoaderEnabled={false}
                numberOfPlaceholders={6}
                mix={{ block: 'ProductListWidget', mods: { extended: !!totalItems } }}
                isWidget
                extended
            />
        );
    }
}

export default ProductListWidgetExtended;
