import { connect } from 'react-redux';

import { ProductListWidgetContainer } from 'Component/ProductListWidget/ProductListWidget.container';

import ProductListWidgetExtended from './ProductListWidgetExtended.component';

/** @namespace Pwa/Component/ProductListWidgetExtended/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});
/** @namespace Pwa/Component/ProductListWidgetExtended/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/ProductListWidgetExtended/Container/ProductListWidgetExtendedContainer */
export class ProductListWidgetExtendedContainer extends ProductListWidgetContainer {
    render() {
        return <ProductListWidgetExtended {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListWidgetExtendedContainer);
